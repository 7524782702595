import { useEffect, useRef, useState } from 'react';

import { Text, AutoCompleteComboBox, InputProps, Icon } from '@ecoinvent/ui';
import { BiSearch } from 'react-icons/bi';

import { FilterMap } from 'hooks/data/search/types';
import useSuggestions from 'hooks/data/search/useSuggestions';

type Props = Omit<InputProps, 'onChange'> & {
  onSearch: (value: string) => void;
  defaultValue: string;
  filters: FilterMap;
};

const STORAGE_KEY = 'recentSearches';

const loadRecentSearches = () => {
  const recentSearches = localStorage.getItem(STORAGE_KEY);
  return (recentSearches ? JSON.parse(recentSearches) : []) as string[];
};

const saveRecentSearches = (recentSearches: string[]) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(recentSearches));
};

const AutoCompleteSearch = ({ onSearch, defaultValue: defaultValue, filters, ...rest }: Props) => {
  const [query, setQuery] = useState(defaultValue || '');
  const [debouncedQuery, setDebouncedQuery] = useState(query);

  const { data: suggestions, isLoading } = useSuggestions({ query: debouncedQuery, filters: filters });

  const recentSearches = loadRecentSearches();

  const myRef = useRef<HTMLInputElement>();

  const handleSubmit = (v: string) => {
    myRef.current?.blur();
    if (v && !recentSearches.includes(v)) {
      const updatedRecentSearches = [...recentSearches];
      if (recentSearches.length >= 5) {
        updatedRecentSearches.pop();
      }
      saveRecentSearches([v].concat(updatedRecentSearches));
    }
    onSearch(v);
  };

  // Update query after 200 ms from the last update of debouncedQuery
  useEffect(() => {
    const timer = setTimeout(() => setQuery(debouncedQuery), 200);
    return () => clearTimeout(timer);
  }, [debouncedQuery]);

  return (
    <AutoCompleteComboBox
      leftElement={<Icon as={BiSearch} />}
      inputValue={debouncedQuery}
      onChange={({ inputValue }) => handleSubmit(inputValue ?? '')}
      onChangeInputValue={(v) => setDebouncedQuery(v)}
      options={query.length > 0 ? suggestions || [] : recentSearches.map((text) => ({ text: text, highlight: text }))}
      itemToString={(item) => item?.text ?? ''}
      placeholder="Search for an activity, product, or by CAS number..."
      isLoading={isLoading}
      renderOption={(item) => <Text dangerouslySetInnerHTML={{ __html: item.highlight }} />}
      colorScheme="green"
      {...rest}
    />
  );
};

export default AutoCompleteSearch;
